import React from "react";
import styles from "./feature.module.scss";

const Feature = () => {
  return (
    <div className={styles.feature}>
      <div className={styles.feature__inner}>
        <div className={styles.feature__image}>
          <h2 className={styles.feature__title}>We're working on something really special&hellip;</h2>
        </div>

        <div className={styles.feature__content}>
          <h3>Get ready for our greatest deals ever!</h3>
          <p>
            Our new pop up model will allow us to bring you the most epic deals on wines from Australia and the world.
            Every wine will be on the site at never before seen prices, for a short period (until the entire container
            is sold out).
          </p>

          <a href="#join">Join now and get notified.</a>
        </div>
      </div>
    </div>
  );
};

export default Feature;
