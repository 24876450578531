import React, { useState } from "react";
import imageOne from "../../static/images/why-popup--1.png";
import imageTwo from "../../static/images/why-popup--2.png";
import imageThree from "../../static/images/why-popup--3.png";
import * as styles from "./why.module.scss";
import * as sharedStyles from "./shared.module.scss";

const reasons = [
  {
    title: "Good wine only.",
    image: imageOne,
    description:
      "We scour the world for wines we love, and taste everything ourselves. You’ll only ever get the good stuff."
  },
  {
    title: "The most epic wine deals.",
    image: imageTwo,
    description:
      "The collective buying power of our awesome tribe of wine-lovers means we can blow the next best market price out of the water."
  },
  {
    title: "Happiness, guaranteed.",
    image: imageThree,
    description:
      "You should only have wine that makes you happy. Don’t love something? No worries, we’ll simply pick it up, free of charge."
  }
];

const selected = {
  title: "Good wine only.",
  image: imageOne,
  description:
    "We scour the world for wines we love, and taste everything ourselves. You’ll only ever get the good stuff."
};

export const Desktop = () => {
  return (
    <div className={styles.why}>
      <h2 className={styles.why__sectionTitle}>Here's why you'll love it here.</h2>

      <ol className={styles.why__list}>
        {reasons.map((reason, idx) => (
          <li key={idx} className={styles.why__listItem}>
            <h3 className={styles.why__title}>{reason.title}</h3>
            <div className={styles.why__image}>
              <img src={reason.image} alt="" />
            </div>
            <p>{reason.description}</p>
          </li>
        ))}
      </ol>

      <div className={sharedStyles.buttons}>
        <a className={sharedStyles.button__button} href="#join">
          <i className={sharedStyles.button__icon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
              <path d="M23 13h2v7c0 1.7-1.3 3-3 3H4c-1.7 0-3-1.3-3-3v-7h2v7c0 .6.4 1 1 1h18c.6 0 1-.4 1-1v-7zm2-7v3.6l-12 6.5L1 9.6V6c0-1.7 1.3-3 3-3h18c1.7 0 3 1.3 3 3zm-2 0c0-.6-.4-1-1-1H4c-.6 0-1 .4-1 1v2.4l10 5.5 10-5.5V6z" />
            </svg>
          </i>
          <span>Join with email</span>
        </a>
      </div>
    </div>
  );
};

export const Mobile = () => {
  const [selected, setSelected] = useState(reasons[0]);

  return (
    <div className={styles.whyMobile}>
      <h2 className={styles.whyMobile__sectionTitle}>Here's why you'll love it here.</h2>

      <div className={styles.whyMobile__buttons}>
        {reasons.map((reason, idx) => (
          <button
            type="button"
            className={`${styles.whyMobile__button} ${selected === reason ? styles.whyMobile__buttonIsActive : undefined}`}
            key={idx}
            onClick={() => setSelected(reason)}
          >
            <img src={reason.image} alt="" />
          </button>
        ))}
      </div>

      <div className={styles.whyMobile__description}>
        <h3 className={styles.whyMobile__title}>{selected.title}</h3>
        <p>{selected.description}</p>
      </div>

      <div className={sharedStyles.buttons}>
        <a className={sharedStyles.button__button} href="#join">
          <i className={sharedStyles.button__icon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
              <path d="M23 13h2v7c0 1.7-1.3 3-3 3H4c-1.7 0-3-1.3-3-3v-7h2v7c0 .6.4 1 1 1h18c.6 0 1-.4 1-1v-7zm2-7v3.6l-12 6.5L1 9.6V6c0-1.7 1.3-3 3-3h18c1.7 0 3 1.3 3 3zm-2 0c0-.6-.4-1-1-1H4c-.6 0-1 .4-1 1v2.4l10 5.5 10-5.5V6z" />
            </svg>
          </i>
          <span>Join the 'fo</span>
        </a>
      </div>
    </div>
  );
};
