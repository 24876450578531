import React from "react";
import popupLogo from "../../static/images/popup-sale-logo.png";
import VinomofoLogo from "./vinomofo-logo";
import styles from "./hero.module.scss";
import sharedStyles from "./shared.module.scss";

const Hero = () => {
  return (
    <div className={styles.popupHero}>
      <div className={styles.popupHero__inner}>
        <div className={styles.popupHero__logo}>
          <VinomofoLogo />
        </div>
        <div className={styles.popupHero__saleLogo}>
          <img src={popupLogo} alt="POP-UP Sale event" />
        </div>
        <div className={styles.popupHero__content}>
          <h1 className={styles.popupHero__heading}>You don’t want to miss this one!</h1>
          <div className={styles.popupHero__join}>
            <p>Join the ‘fo be notified</p>
            <div className={sharedStyles.buttons}>
              <a className={sharedStyles.button__button} href="#join">
                <i className={sharedStyles.button__icon}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                    <path d="M23 13h2v7c0 1.7-1.3 3-3 3H4c-1.7 0-3-1.3-3-3v-7h2v7c0 .6.4 1 1 1h18c.6 0 1-.4 1-1v-7zm2-7v3.6l-12 6.5L1 9.6V6c0-1.7 1.3-3 3-3h18c1.7 0 3 1.3 3 3zm-2 0c0-.6-.4-1-1-1H4c-.6 0-1 .4-1 1v2.4l10 5.5 10-5.5V6z" />
                  </svg>
                </i>
                <span>Join with email</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
