import React from "react";
import styled from "styled-components";

import { Helmet } from "react-helmet";
import { GlobalStyle } from "@vinomofo/components";

import styles from "../styles/index.module.scss";

import { Desktop, Mobile } from "../components/why";
import Hero from "../components/hero";
import Feature from "../components/feature";
import JoinForm from "../components/join-form";

export default () => {
  return (
    <React.Fragment>
      <div className={styles.container}>
        <Helmet
          defaultTitle="POP-UP Sale event | Vinomofo New Zealand"
          titleTemplate="%s | Vinomofo New Zealand"
        >
          <link
            rel="stylesheet"
            href="https://fonts.vinomofo.io/css?family=GT+Walsheim:500,700&family=Graphik:400,400italic,500,500italic"
          />
        </Helmet>
        <Hero />
        <Desktop />
        <Mobile />
        <Feature />
        <JoinForm />
      </div>
    </React.Fragment>
  );
};
