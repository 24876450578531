import React, { useState } from "react";
import styles from "./join-form.module.scss";
import sharedStyles from "./shared.module.scss";

const JoinForm = () => {
  const [name, setName] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [isFormValid, setIsFormValid] = useState(false);

  const submitEnabled = (): boolean =>
    undefined !== name &&
    undefined !== email;

  return (
    <div id="join" className={styles.popupJoin}>
      <h3 className={styles.popupJoin__title}>Are you ready to vino, mofo?</h3>
      <p className={styles.popupJoin__subtitle}>Let’s get this show on the road then.</p>
      <div className={styles.popupJoin__form}>
        <form
          name="Contact Form"
          data-netlify="true"
          method="POST"
          action="/success"
        >
          <input type="hidden" name="form-name" value="Contact Form" />
          <div>
            <label className={styles.popupJoin__formLabel}>Name</label>
            <input 
              className={styles.popupJoin__formInput}
              type="text"
              name="name"
              onChange={(e) => {setName(e.target.value); setIsFormValid(submitEnabled());}}
            />  
          </div>
          <div className={styles.popupJoin__spacer} />
          <div>
            <label className={styles.popupJoin__formLabel}>Email</label>
            <input
            className={styles.popupJoin__formInput}
            type="email"
            name="email"
            onChange={(e) => {setEmail(e.target.value); setIsFormValid(submitEnabled());}}
          />
          </div>
          <div className={styles.popupJoin__spacer} />
          <div className={sharedStyles.buttons}>
            <button
              type="submit"
              className={sharedStyles.button__submitButton}
              disabled={!isFormValid}
            >
              Join now
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default JoinForm;
